&.block-window {
    // border-radius: $borderRadius;
    margin: 0 auto 20px;
    box-shadow: $boxShadow;
    border: 1px solid $borderColor;
    display: flex;
    width: 100%;
    flex-direction: column;
    cursor: default;
    align-self: center;

    &.column {
        flex: 1;
        align-Self: unset;
    }

    .window-card {
        padding: 22px 25px 15px 25px;
        font: 500 14px/20px $bf;
        background: #fff;
        // vertical-align: middle;
        position: relative;
        color: #2f2e43;
        display: flex;
        align-items: stretch;
        flex-direction: column;
        width: 100%;
        flex: 1;

    }

    .window-header {
        padding: 15px 10px 15px 25px;
        font: 500 14px/20px $bf;
        background: #fff;
        vertical-align: middle;
        position: relative;
        color: #2f2e43;
        display: flex;
        width: 100%;
        min-width: 800px;
        min-height: 102px;
        align-items: center;

        .window-header-logo {
            display: flex;
            height: 25px;
            margin: -2.5px 10px -2.5px 0;
        }

        .window-header-container {
            display: flex;
            flex-direction: column;
            width: 100%;

            .window-header-row {
                display: flex;
                flex-direction: row;
                padding-bottom: 15px;
                width: 100%;
                
                &:last-child {
                    padding-bottom: 0;
                }

                &.window-header-row-narrow {
                    padding-bottom: 0px;
                }
            }
        }

        .window-header-block {
            padding-left: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            justify-items: center;

            .window-header-block-drown {
                margin: -6px 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                justify-items: center;
            }

            .block-search {
                .form-control {
                    background-color: #f6f7fb;

                    &:focus {
                        background-color: #fff;
                    }
                }
            }

            .form-control {
                box-shadow: none;
                padding-top: 5px;
                padding-bottom: 5px;
    
                &:focus {
                    box-shadow: $boxShadow;
                }
            }

            .form-checkbox {
                .checkbox-label {
                    margin: 0;
                }
            }

            .button {
                padding-top: 5px;
                padding-bottom: 5px;
            }

            &:first-child {
                padding-left: 0;
            }
        }

        &:first-child {
            border-top-left-radius: $borderRadius;
            border-top-right-radius: $borderRadius;
        }

        &:last-child {
            border-bottom-left-radius: $borderRadius;
            border-bottom-right-radius: $borderRadius;
        }

        &.window-header-primary {
            background-color: #f7fafc;

            .block-search {
                .form-control {
                    background: #fff;
                }
            }
        }

        &.padless {
            padding: 0;
        }

        &.borderless {
            border: 0;
        }

        &.window-header-compact {
            padding: 5px 10px 5px 25px;
        }

        &.window-header-even {
            padding: 15px 10px 15px 10px;
        }

        &.window-header-even-wide {
            padding: 15px 25px 15px 25px;
        }
    }

    .window-card-header {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 20px 20px;
        background: #fff;
    } 

    .window-card-header2 {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 25px 25px;
        background: #fff;
    } 

    .window-body {
        border-top: 1px solid $borderColor;
        padding: 25px 25px;
        //border-bottom: 1px solid $borderColor;
        background: #fff;
        font: 400 13px/20px $bf;

        .window-heading {
            margin: 0 0 10px;
            color: #2f2e43;
            font: 500 14px/15px $bf;

            &.window-heading-md {
                font: 500 16px/20px $bf;
            }

            &.window-heading-lg {
                font: 500 22px/28px $bf;
            }

            &.last-child,
            &:last-child {
                margin-bottom: 0;
            }
        }

        .window-text {
            font: inherit;
            margin: 0 0 5px;
            vertical-align: middle;

            .mdi {
                font-size: 1.4em;
                vertical-align: middle;
            }
        }

        // &:last-child {
        //     border-radius: 0 0 $borderRadius $borderRadius;
        // }

        &>*:last-child {
            margin-bottom: 0;
        }

        &.primary,
        &.window-body-primary {
            background-color: #f7fafc;
        }

        &.success,
        &.window-body-success {
            background-color: #f3fef3;
        }

        &.padless,
        &.window-body-padless {
            padding: 0;
        }

        &.padless-x,
        &.window-body-padless-x {
            padding-left: 0;
            padding-right: 0;
        }

        &.padless-y,
        &.window-body-padless-y {
            padding-top: 0;
            padding-bottom: 0;
        }

        &.borderless,
        &.window-body-borderless {
            border: 0;
        }

        &.compact,
        &.window-body-compact {
            padding: 20px 20px;
        }
    }

    .window-footer {
        padding: 10px 10px 10px 25px;

        &.primary {
            background-color: #f7fafc;
        }

        &.padless {
            padding: 0;
        }

        &.borderless {
            border: 0;
        }

        &.window-header-even {
            padding: 10px 10px 10px 10px;
        }

        &.window-header-even-wide {
            padding: 15px 25px 15px 25px;
        }
    }

    .window-row {
        display: flex;
        flex-direction: row;

        .window-col {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            width: 1px;
            border-right: 1px solid $borderColor;

            &>.window-body {
                &:last-of-type {
                    height: 100%;
                    border-bottom: none;
                }
            }

            &:last-child {
                border-right: none;
            }
        }
    }
}