&.block-card-service {
    margin: 0 0 $marginBottom;
    padding-top: 25px;
    padding-left: 25px;
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    background-color: #fff;
    box-shadow: $boxShadowLabel;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .card-user-media-abbr {
        width: 16px;
        height: 16px;
        display: block;
        color: #fff;
        border-radius: 9px;
        text-align: center;
        font: 700 10px/16px $bf;
        background-color: $colorPrimary;
    }

    .company-name {
        color: $tc;
        font: 700 14px/14px $bf;
        max-width: 210px;
        margin-bottom: 4px;   
    }
}