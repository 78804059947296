&.block-receipt-pane {
    display: flex;
    flex-direction: column;
    margin: 0 0 $marginBottom;
    color: $tc;
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    background-color: #fff;
    box-shadow: $boxShadowLabel;
    overflow: hidden;
    cursor: pointer;

    &.without-shadow {
        box-shadow: none;
    }

    .receipt-overview {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        padding: 15px 20px 15px;
        border-bottom: 1px solid $borderColor;

        .receipt-media {
            width: 36px;
            height: 36px;
            margin-right: 10px;

            img {
                display: block;
                width: 100%;
            }
        }

        .receipt-info {
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            .receipt-title {
                display: flex;
                flex-direction: row;
                font: 700 14px/20px $bf;
                text-transform: uppercase;

                strong {
                    font-weight: 700;
                }
            }

            .receipt-info-list {
                display: flex;
                flex-direction: row;

                .receipt-info-item {
                    display: flex;
                    font: 500 14px/20px $bf;
                    padding-right: 20px;
                    position: relative;

                    .mdi {                      
                        margin-right: 2.5px;
                    }
                    .primary {
                        color: $colorPrimary;
                    }

                    &:after {
                        content: "";
                        display: block;
                        width: 4px;
                        height: 4px;
                        border-radius: 4px;
                        background-color: #9e9e9e;
                        position: absolute;
                        right: 8px;
                        top: 50%;
                        transform: translate(0, -50%);
                    }

                    &:last-child {
                        padding-right: 0;

                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }

        .receipt-value {
            .receipt-amount {
                color: $colorPrimary;
                justify-content: flex-end;
            }
        }

        &:last-child {
            border-bottom: none;
        }
    }

    .receipt-value {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        position: relative;
        align-items: center;

        .receipt-amount {
            display: flex;
            flex-direction: row;
            font: 700 16px/18px $bf;
            justify-content: flex-start;
            margin: 0 0 2px;

            sup {
                font-size: 10px;
                margin-top: -2px;
            }

            img {
                display: block;
                margin: 0 0 0 5px;
                width: 18px;
                height: 18px;

                &:first-child {
                    margin: 0 5px 0 0;
                }
            }
        }

        .receipt-label {
            display: flex;
            flex-direction: row;
            color: #919191;
            font: 500 12px/20px $bf;
        }
    }

    .receipt-details {
        display: flex;
        flex-direction: row;
        background-color: #f8f9f9;

        .receipt-arrow {
            display: flex;
            flex-direction: row;
            justify-content: center;
            position: absolute;
            border: 1px solid $borderColor;
            border-radius: 50px;
            width: 28px;
            height: 28px;
            line-height: 26px;
            left: 100%;
            top: 50%;
            transform: translate(-50%, -50%);
            background-color: #fff;

            .mdi {
                font-size: 18px;
                color: $colorPrimary;
            }
        }

        .receipt-value {
            padding: 15px 20px 15px;
            flex-basis: 50%;
            border-right: 1px solid $borderColor;

            .receipt-amount,
            .receipt-label {
                color: #919191;
            }

            &:last-child {
                border-right: none;

                .receipt-amount,
                .receipt-label {
                    justify-content: flex-end;
                }
            }
        }
    }

    .receipt-payment {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 15px 20px 15px;
        border-bottom: 1px solid $borderColor;

        .payment-title {
            font: 500 14px/20px $bf;
            color: #6f747e;
            margin: 0 0 10px;
        }

        .payment-overview {
            display: flex;
            flex-direction: row;

            .payment-value {
                display: flex;
                flex-direction: row;
                position: relative;
                padding-top: 8px;

                .payment-currency {
                    width: 25px;
                    height: 25px;
                    margin: 0 10px 0 0;

                    img {
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                }

                .payment-label {
                    position: absolute;
                    left: 100%;
                    margin-left: 5px;
                    top: 0px;
                }

                .payment-amount {
                    font: 700 30px/25px $bf;

                    small {
                        font: 700 14px/25px $bf;
                        color: #c5c5c5;
                    }

                    &.payment-amount-default {
                        color: #000;
                    }
                }
            }

            .payment-actions {
                display: flex;
                flex-grow: 1;
                flex-direction: row;
                justify-content: flex-end;
            }
        }
    }
}
