&.block-current-plan-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // margin-top: 20px;
  gap: 20px;

  
  .card {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 18px 20px;
  
    
    // width: 100%/3;
    flex: 0 0 33.333333%;
    min-height: 141px;
    
    background: #FFFFFF;
    border: 1px solid #EAECF0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
    position: relative;
    .title {
      font: 500 14px/21px $bf;
      color: #202531;
    }
    .subtitle {
      margin-top: 18px;
      font: 700 24px/22px $bf;
      color: #202531;
    }
    .mdi {
      position: absolute;
      top: 25px;
      right: 10px;
      font-size: 20px;
      line-height: 10px;
      color: #C7C4C3;
      &:hover {
        cursor: pointer;
        color: #6b6968;    
      }   
    }
    .plan-point {
      margin-top: 15px;
      display: flex;
      .plan-point-title {
        font: 500 13px/13px $bf;
        color: #0C161D;
      }
      &:hover {
        cursor: pointer;
      }
      &:hover .plan-point-title {
        text-decoration: underline;
        color: $colorPrimary;
      }
      .mdi {
        position: relative;
        top: auto;
        right: auto;
        margin-left: 6px;
        font-size: 22px;
        line-height: 12px;
        color: $colorPrimary; 
      }
      &:hover .mdi {
        color: #0C161D;
      }
    }

  }
}