&.dropdown-permissions {
    position: absolute;
    display: flex;
    flex-direction: column;
    margin: 0 0 $marginBottom;
    color: $tc;
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    background-color: #fff;
    box-shadow: $boxShadowLabel;
    max-height: 260px;
    overflow-y: auto;
    min-width: 432px;
    left: 0;
    top: 100%;
    @include webkit_scrollbar();

    .permission-item {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid $borderColor;
        padding: 15px;
        background-color: #fff;
        transition: 0.4s;

        .permission-actions {
            padding-right: 15px;

            .form-checkbox {
                padding: 0;
            }
        }

        .permission-details {
            display: flex;
            flex-direction: column;

            .permission-name {
                font: 700 18px/22px $bf;
                margin: 0 0 5px;
            }

            .permission-description {
                font: 500 12px/18px $bf;
                margin: 0 0 0;
                color: #919191;
            }
        }

        &[for] {
            cursor: pointer;

            &:hover {
                background-color: #f6f6f6;
            }
        }
    }
}
