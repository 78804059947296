&.block-current-plan {
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 18px 20px;
  gap: 10px;
  
  width: 100%;

  
  background: #FFFFFF;
  border: 1px solid #EAECF0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  
  .block-current-plan-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
  .block-current-plan-name {
    font: 700 24px/22px $bf;
    letter-spacing: -0.408px;
    color: $tc;
  }
  .block-current-plan-price {
    font: 700 12px/16px $bf;
    text-align: right;
    color: #555555;
  }
  .block-current-plane-change {
    margin-top: 3px;
    font: 500 12px/16px $bf;
    text-align: right;
    color: $colorPrimary;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
      font-weight: 700;
    }
  }
  .block-current-plan-total{
    margin-top: 18px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
  .block-current-plan-total-title {
    font: 700 14px/21px $bf;
    color: #000000;
  }
  .block-current-plan-total-payment {
    font: 500 12px/12px $bf;
    color: $tc;
  }
  .block-current-progress-wrapper {
    margin-top: 12px;
    width: 100%;
    height: 5px;

    /* #F4F4F1 */
    background: #F4F4F1;
    border-radius: 40px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }
  .block-current-plan-footer {
    margin-top: 6px;
    display: flex;
    flex-direction: row;
    width: 100%;
    .footer-item {
      font: 400 12px/16px $bf;
      color: #555555;
    }
  }
}