&.block-notifications-popup {
    display: flex;
    flex-direction: column;
    margin: 0 0 $marginBottom;
    color: $tc;
    border: 1px solid $borderColor;
    background-color: #fff;
    width: 375px;
    box-shadow: $boxShadowPopUp;
    border-radius: $borderRadius;

    .notifications-header {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid $borderColor;
        padding: 15px 15px;

        .notifications-header-title {
            display: flex;
            font: 700 18px/26px $bf;
            flex-grow: 1;
        }

        .notifications-header-link {
            display: flex;
            color: $colorPrimary;
            font: 700 14px/26px $bf;
        }
    }

    .notifications-body {
        height: 600px;
        max-height: 50vh;
        overflow: auto;
        @include webkit_scrollbar($colorPrimary, #fff);

        .notification-item {
            display: flex;
            flex-direction: row;
            padding: 15px 20px;
            border-bottom: 1px solid $borderColor;

            .notification-item-details {
                display: flex;
                flex-direction: column;
                flex-grow: 1;

                .notification-item-title {
                    font: 400 14px/20px $bf;
                    max-width: 270px;

                    &.bold {
                        font-weight: 700;
                    }
                }

                .notification-item-date {
                    font: 500 14px/20px $bf;
                    color: #6f747e;

                    .mdi {
                        color: $colorPrimary;
                        margin-right: 5px;
                    }
                }
            }

            .notification-item-media {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                justify-self: flex-start;
                height: 36px;
                width: 36px;
                padding: 8px;
                border-radius: 36px;
                background-color: #F4F4F1;

                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }
}
