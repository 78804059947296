&.block-overview_cards {
    display: flex;
    flex-direction: row;
    margin: 0 0 $marginBottom;
    color: $tc;

    .overview_card {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-basis: 25%;
        margin-right: $marginBottom;
        border-radius: $borderRadius;
        border: 1px solid $borderColor;
        box-shadow: $boxShadowLabel;
        background-color: #fff;
        padding: $marginBottom;

        .overview_card-title {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
            
            .overview_card-title-icon {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-right: 5px;
                width: 20px;

                img {
                    display: block;
                    max-width: 100%;
                    max-height: 100%;
                    margin: 0 auto;
                }
            }

            .overview_card-title-label {
                font: 500 14px/20px $bf;
            }
        }

        .overview_card-value {
            display: flex;
            flex-direction: row;
            align-items: center;       

            .overview_card-value-amount {
                font: 700 28px/28px $bf;   
                margin-bottom: -5px;
            }

            .overview_card-value-card_info {
                font: 700 22px/22px $bf;   
                margin-bottom: -5px;
            }

            .overview_card-value-currency {
                padding: 3px;
                width: 28px;
                height: 28px;
                margin-right: 2.5px;           

                img {
                    display: block;
                }
            }

            &.overview_card-value-danger {
                color: $colorDanger;
            }

            &.overview_card-value-success {
                color: $colorSuccess;
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }
}
