&.block-users {
    display: flex;
    flex-direction: column;
    margin: 0 0 $marginBottom;
    color: $tc;
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    background-color: #fff;
    box-shadow: $boxShadowLabel;
    padding: 0 20px;

    .user {
        border-bottom: 1px solid $borderColor;
        display: flex;
        flex-direction: row;
        padding: 15px 0;

        .user-media {
            display: flex;
            flex-direction: column;
            width: 30px;
            height: 30px;
            border-radius: 30px;
            overflow: hidden;
            margin-right: 10px;
            justify-content: flex-start;

            .user-media-image {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: contain;
                border-radius: inherit;
            }
            
            .user-media-abbr {
                display: block;
                width: 100%;
                line-height: 30px;
                text-align: center;
                background-color: $colorPrimary;
                font: 700 15px/30px $bf;
                color: #fff;
                border-radius: inherit;
                
            }

            .user-media-container {
                display: block;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $colorPrimary;
                border-radius: inherit;
                .user-media-image-in-container {
                    display: block;
                    width: 15px;
                    height: 15px;
                    object-fit: contain;
                }
            }
        }

        .user-details {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: center;

            .user-name {
                font: 500 17px/20px $bf;
                flex-grow: 1;
                justify-content: center;

                &.italic {
                    font-style: italic;
                }

                .label {
                    height: fit-content;
                    margin-left: 2px;
                    vertical-align: text-top;
                }

                &:last-child {
                    line-height: 30px;
                }
            }

            .user-email {
                display: flex;
                font: 500 13px/20px $bf;
                flex-grow: 1;
                color: #919191;
            }
        }

        .user-label {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &:last-child {
            border-bottom: none;
        }
    }
}
