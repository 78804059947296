&.block-modal-right {

    &.after-open {
        -webkit-transform: -webkit-translateX(250px);
        transform: translateX(-250px);
    }

    &.before-close {       
        -webkit-transform: -webkit-translateX(250px);
        transform: translateX(250px);   
    }
    

    &.overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: -250px;
        bottom: 0;
        background-color: transparent;
        -webkit-transition:  -webkit-transform 200ms;
        transition:  transform 200ms;

    }
    &.content {  
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 300px;
        top: 0px;
        left: auto;
        right: 0px;
        bottom: 0px;
        // transform: translate(0px, 300px);
        padding: 0px;
        overflow: none;
        border: 1px solid #ccc;
        background: #fff;
        -webkit-overflow-scrolling: touch;
        font-family: $bf;
    }
  }


