&.block-pagination {
    display: flex;
    flex-direction: column;
    margin: 0 0 $marginBottom;
    color: $tc;
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    background-color: #fff;
    box-shadow: $boxShadowLabel;
    padding: 20px;;
}
