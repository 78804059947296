&.block-circle-pane {
    display: flex;
    flex-direction: row;
    margin: 0 0 $marginBottom;
    color: $tc;
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    background-color: #fff;
    padding: 15px 20px;
    box-shadow: $boxShadowLabel;
    cursor: pointer;

    &.without-shadow {
        box-shadow: none;
    }

    .circle-media {
        width: 36px;
        height: 36px;
        margin-right: 10px;

        img {
            display: block;
            width: 100%;
        }
    }

    .circle-details {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        .circle-title {
            display: flex;
            flex-direction: row;
            font: 400 14px/20px $bf;

            strong {
                font-weight: 700;
            }
        }

        .circle-members {
            display: flex;
            flex-direction: row;
            color: $colorPrimary;
            font: 500 12px/20px $bf;

            .mdi {
                font-size: 15px;
                line-height: 20px;
                margin-right: 2.5px;
            }

            .circle-members-label {
                color: $colorDark;
                font: 500 12px/20px $bf;
            }
        }
    }
}
