&.block-permissions {
    display: flex;
    flex-direction: column;
    margin: 0 0 $marginBottom;
    color: $tc;
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    background-color: #fff;
    box-shadow: $boxShadowLabel;

    .permissions-overview {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid $borderColor;
        padding: 15px 20px;

        .permissions-media {
            display: block;
            margin-right: 10px;
        }

        .permissions-details {
            display: flex;
            flex-direction: column;

            .permissions-title {
                font: 700 16px/20px $bf;
            }
            
            .permissions-subtitle {
                font: 500 12px/16px $bf;
                color: #919191;
            }
        }

        &:last-child {
            border-bottom: none;
        }
    }

    .permissions-list {
        .permissions-item {
            display: flex;
            border-bottom: 1px solid $borderColor;
            padding: 15px 20px;

            .permissions-item-details {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
            }

            .permissions-item-title {
                font: 700 15px/20px $bf;
            }

            .permissions-item-subtitle {
                font: 500 12px/20px $bf;
                color: #919191;
            }

            .permissions-item-actions {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            &:last-child {
                border-bottom: none;
            }

            &[for] {
                cursor: pointer;
                transition: background-color .4s;
                
                &:hover {
                    background-color: #f8f8f8;
                }
            }
        }
    }
}