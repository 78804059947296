.label {
    display: inline-flex;
    border: 1px solid $borderColor;
    border-radius: max($borderRadius - 1, 0);
    font: 700 11px/16px $bf;
    padding: 0 5px;
    color: $colorPrimaryLight;
    background: #fff;
    box-shadow: none;

    .mdi {
        display: inline;
        font-size: 1.2em;
        
        &.label-icon-start {
            margin-right: 4px;
        }
        
        &.label-icon-end {
            margin-left: 4px;
        }
    }

    &.label-primary {
        color: #fff;
        background: #424770;
        border-color: #424770;
    }

    &.label-primary-light {
        color: #0000ff;
        background: #d6ecff;
        border-color: #d6ecff;
    }

    &.label-primary-outline {
        color: #0C161D;
        border-color: #0C161D;
        background: #fff;
    }

    &.label-text {
        background: transparent;
        border: none;
    }

    &.label-default {
        background-color: #e3e8ee;
        border-color: #e3e8ee;
        color: $tc;
    }

    &.label-default-outline {
        background-color: #fff;
        border-color: #919191;
        color: #919191;
    }

    &.label-success {
        color: #fff;
        border-color: #34c433;
        background-color: #34c433;
    }

    &.label-success-light {
        color: #17aa51;
        border-color: #cffacf;
        background-color: #cffacf;
    }

    &.label-success-payment {
        color: #fff;
        border-color: #1B9477;
        background-color:  #1B9477;
    }
   

    &.label-success-dim {
        color: #fff;
        border-color: #2da02d;
        background-color: #2da02d;
    }

    &.label-warning {
        color: #fff;
        border-color: $colorWarning;
        background-color: $colorWarning;
    }

    &.label-warning-light {
        color: #45535e;
        border-color: #f5f79b;
        background-color: #f5f79b;
    }

    &.label-danger {
        background-color: $colorDanger;
        border-color: $colorDanger;
        color: #fff;
    }

    &.label-danger-light {
        background-color: $colorDangerLight;
        border-color: $colorDangerLight;
        color: $colorDanger;
    }

    &.label-default-dashed {
        border: 1px dashed $borderColor;
        background: #fff;
        color: $tc;
    }

    &.label-shadow {
        box-shadow: $boxShadowLabel;
    }

    &.label-round {
        border-radius: 15px;
    }

    &.label-md {
        font: 700 11px/14px $bf;
        padding: 2px 6px;
    }

    &.label-lg {
        font: 700 12px/16px $bf;
        padding: 4px 8px;
    }
}