&.block-auth {
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100vw;
    min-width: 1200px;

    .auth-content,
    .auth-aside {
        width: 50%;
        flex-basis: 50%;
    }

    .auth-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: auto;

        .auth-container {
            padding: 40px 0;
            display: block;
            max-height: 100%;
            overflow: auto;
            @include webkit_scrollbar_gray($colorPrimaryLight);
        }

        .auth-wrapper {
            margin: 0 auto;
            width: 360px;

            &.auth-wrapper-lg {
                width: 500px;
            }
        }

        .auth-logo {
            img {
                display: block;
            }
        }

        .auth-title {
            font: 700 32px/40px $bf;
            margin: 0 0;
        }

        .auth-description {
            font: 400 14px/25px $bf;
            margin: 0 0 20px;
        }

        .auth-recover {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            color: $colorPrimary;

            .auth-recover-icon {
                color: inherit;
                font-size: 15px/20px;
                margin-right: 5px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .mdi {
                    line-height: 20px;
                }
            }

            .auth-recover-label {
                color: inherit;
                font: 500 13px/20px $bf;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }

    .auth-aside {
        display: flex;
        position: relative;
        background-color: $colorPrimary;;
        background-position: top left;
        background-repeat: no-repeat;
        background-size: 50%;

        &:after {
            content: "";
            position: absolute;
            pointer-events: none;
            background-image: url("/assets/img/auth-bottom-shape.svg");
            background-position: bottom right;
            background-repeat: no-repeat;
            background-size: 55%;
            @include fill_parent();
        }

        &.auth-aside-sign_up {
            background-color: $colorPrimary;
            &:after {
                background-image: url("/assets/img/auth-bottom-shape.svg");
            }
        }
    }
}
