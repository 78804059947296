@font-face {
    font-family: "Azo Sans";
    font-style: normal;
    font-weight: 100;
    src: local("Azo Sans Thin"), url("../../assets/fonts/azo-sans/Rui\ Abreu\ -\ AzoSans-Thin.woff") format("woff");

}

@font-face {
    font-family: "Azo Sans";
    font-style: normal;
    font-weight: 300;
    src: local("Azo Sans Light"), url("../../assets/fonts/azo-sans/Rui\ Abreu\ -\ AzoSans-Light.woff") format("woff");
}

@font-face {
    font-family: "Azo Sans";
    font-style: normal;
    font-weight: 400;
    src: local("Azo Sans Regular"), url("../../assets/fonts/azo-sans/Rui\ Abreu\ -\ AzoSans-Regular.woff") format("woff");
}

@font-face {
    font-family: "Azo Sans";
    font-style: normal;
    font-weight: 500;
    src: local("Azo Sans"), url("../../assets/fonts/azo-sans/Rui\ Abreu\ -\ AzoSans-Medium.woff") format("woff");
}

@font-face {
    font-family: "Azo Sans";
    font-style: normal;
    font-weight: 700;
    src: local("Azo Sans Bold"), url("../../assets/fonts/azo-sans/Rui\ Abreu\ -\ AzoSans-Bold.woff") format("woff");
}

@font-face {
    font-family: "Azo Sans";
    font-style: normal;
    font-weight: 900;
    src: local("Azo Sans Black"), url("../../assets/fonts/azo-sans/Rui\ Abreu\ -\ AzoSans-Black.woff") format("woff");
}