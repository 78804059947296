.pull-right { float: right; }
.pull-left { float: left; }

.text-right { text-align: right; }
.text-center { text-align: center; }
.text-left { text-align: left; }

.text-strike { text-decoration: line-through; }
.text-muted { color: #a1a1a1; }

.hidden { display: none; }

.cf { @include cf(); }

.flex {
    display: flex;
}

.flex-direction-row {
    flex-direction: row;
}

.flex-direction-column {
    flex-direction: column;
}

.flex-grow {
    flex-grow: 1;
}

.flex-center {
    justify-content: center;
}

.flex-end {
    justify-content: flex-end;
}

.flex-start {
    justify-content: flex-start;
}

.text-primary {
    color: $colorPrimary;
}

.hide-sm {
    @media screen and (max-width: 1000px) {
        display: none !important;
    }
}

.show-sm-block {
    @media screen and (max-width: 1000px) {
        display: block !important;
    }
}

.show-sm-flex {
    @media screen and (max-width: 1000px) {
        display: flex !important;
    }
}

.hide-md {
    @media screen and (min-width: 1000px) {
        display: none !important;
    }
}

.show-md-block {
    @media screen and (min-width: 1000px) {
        display: block !important;
    }
}

.show-md-flex {
    @media screen and (min-width: 1000px) {
        display: flex !important;
    }
}

.nowrap {
    white-space: nowrap;
}

.white {
    color: white;
}

.wrapper {
    margin: 0 auto 20px;
    max-width: 100%;
    width: 1200px;

    &.wrapper-sm {
        width: 600px;
    }
}