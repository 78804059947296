&.block-invite-modal {
    .invite-list {
        width: 100%;
        display: table;
        flex-direction: column;

        .invite-item {
            display: table-row;
            flex-direction: row;

            .invite-item-cell,
            .invite-item-action {
                display: table-cell;
                padding: 5px 10px;
                vertical-align: top;
                width: 22%;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
                &.invite-last {
                    padding-right: 0;
                    width: 34%;
                }
            }

            .invite-item-action {
                width: 0%;
            }
        }
    }
}
