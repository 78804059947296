&.block-overview-list {
    margin: 0 0 $marginBottom;
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    background-color: #fff;
    box-shadow: $boxShadowLabel;
    display: flex;
    flex-direction: column;
    padding: 20px 25px 0;

    .overview-item {
        margin: 0 0 20px;

        .overview-item-label,
        .overview-item-value {
            color: #202531;
            margin: 0 0 0;
        }

        .overview-item-label {
            font: 700 14px/20px $bf;
        }

        .overview-item-value {
            font: 500 12px/20px $bf;
        }
    }
}
