&.dropdown-user_selector {
    display: flex;
    flex-direction: column;
    background-color: #1f2126;
    border-radius: $borderRadius;
    padding: 5px 0;
    min-width: 220px;

    .user_selector-media {
        display: flex;
        flex-direction: column;
        font: 700 14px/22px $bf;
        margin-right: 10px;
        justify-content: center;

        .user_selector-media-abbr {
            color: #fff;
            background-color: #484848;
            width: 22px;
            height: 22px;
            border-radius: 22px;
            text-align: center;
            font-size: 12px;
        }

        .user_selector-media-image {
            display: block;
            width: 22px;
            height: 22px;
            border-radius: 22px;
            overflow: hidden;

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    .user_selector-client {
        display: flex;
        flex-direction: row;
        padding: 7.5px 15px;

        .user_selector-details {
            display: flex;
            flex-direction: column;

            .user_selector-client-name {
                font: 700 14px/22px $bf;
                color: #fff;
            }

            .user_selector-client-email {
                font: 400 12px/18px $bf;
                color: #fff;
            }
        }
    }

    .user_selector-actions,
    .user_selector-organizations {
        .user_selector-action,
        .user_selector-organization {
            display: flex;
            flex-direction: row;
            padding: 7.5px 10px;
            font: 500 14px/22px $bf;
            background-color: #1e2226;
            transition: background-color 0.4s;
            cursor: pointer;

            &:hover {
                background-color: #383838;
            }
        }
    }

    .user_selector-organizations {
        border-bottom: 1px solid #000;
        padding-bottom: 5px;

        .user_selector-organization {
            .user_selector-organization-name {
                display: flex;
                flex-grow: 1;
                font: inherit;
                color: #fff;
            }

            .user_selector-organization-checkmark {
                display: flex;
                flex-direction: column;
                justify-content: center;

                .mdi {
                    display: flex;
                    flex-direction: row;
                    width: 18px;
                    height: 18px;
                    line-height: 18px;
                    text-align: center;
                    justify-content: center;
                    background-color: $colorPrimary;
                    border-radius: 18px;
                }
            }
            .user_selector-organization-menu-item-notes {
                background-color: #dd1a1e;
                border-radius: 15px;
                min-width: 15px;
                max-width: 25px;
                line-height: 15px;
                height: 15px;

                display: flex;
                flex-direction: column;
                justify-content: center;

                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;    
               
                padding: 0px 3px;
               
                text-align: center;
                justify-content: center;
                font-size: x-small; 
            }
        }
    }

    .user_selector-actions {
        display: flex;
        flex-direction: column;
        padding-top: 5px;

        .user_selector-action {
            .user_selector-action-icon {
                width: 22px;
                margin-right: 10px;

                .mdi {
                    display: block;
                    width: 22px;
                    height: 22px;
                    color: #fff;
                    text-align: center;
                    font-size: 18px;
                    line-height: 22px;
                }
            }

            .user_selector-action-name {
                display: flex;
                flex-grow: 1;
            }
        }
    }
}
