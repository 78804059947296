$pad: $wrapperPadding / 2;
$columns: 12;
$media_md: 1600px;
$media_sm: 1300px;
$media_xs: 1000;

.flex-row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-right: -$pad;
    margin-left: -$pad;

    &+flex-row {
        margin-top: $pad;
    }

    &.flex-row-padless {
        margin-right: 0;
        margin-left: 0;
    }
}

.flex-col-auto {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 0 $pad;
}

.flex-col {
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    max-width: 100%;
    padding: 0 $pad;

    &.flex-col-padless {
        padding-right: 0;
        padding-left: 0;
    }
}

.flex-center {
    justify-items: center;
    justify-content: center;
}