.form {
    .form-title {
        display: flex;
        font: 700 16px/20px $bf;
        margin: 0 0 $marginBottom / 2;
        text-transform: uppercase;
    }

    .form-range {
        display: flex;
        flex-direction: row;

        .form-range-col {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .form-control {
                min-width: auto;
            }
        }

        .form-range-label {
            font: 500 12px/20px $bf;
            padding: 8px 12px;
        }
    }

    .form-group {
        position:relative;
        flex-direction: column;
        display: flex;
        margin: 0 0 $marginBottom;

        &.form-group-inline {
            display: flex;
            flex-direction: row;

            & > .form-label,
            .form-group-inline-key {
                width: 140px;
                min-width: 140px;
                max-width: 140px;
                line-height: 36px;
                text-align: right;
                padding-right: 10px;
                color: #000000;
                font: 500 13px/36px;

                .form-control {
                    width: 100%;
                    min-width: 100%;
                }
             
            }

            .form-group-inline-value {
                flex-grow: 1;

                .flex-row {
                    margin: 0 -5px;

                    .flex-col {
                        padding-left: 5px;
                        padding-right: 5px;
                    }
                }
            }
        }

        .form-subgroup-flex-button {
            display: flex;

            button {
                margin-left: 20px;
            }
        }

        &.form-group-marginless {
            margin-bottom: 0;
        }
    }

    .form-label {
        font: 500 12px/24px $bf;
        color: #080808;
    }

    .form-label2 {
        font: 400 12px/24px $bf;
        color: #080808;
    }

    .form-value {
        padding: 7px 0;
        font: 500 13px/20px $bf;
    }

    .form-emoji-button {
        position: absolute;
        left: 20px;
        // top: 14px;
        // z-index: 999;

        &.without-label {
            top: 10px;
        }

        &:hover {
            cursor: pointer;
            .mdi {               
                color: $colorPrimary;
            }
        }

    }

    .form-control {
        display: block;
        flex-grow: 1;
        border: 1px solid $borderColor;
        border-radius: $borderRadius;
        padding: 8px 12px;
        font: 500 15px/20px $bf;
        box-shadow: $boxShadow;
        outline: none;
        transition: box-shadow 0.4s;
        background: #fff;
        color: $tc;
        margin: 0;
        width: 100%;
        min-width: 160px;
        max-width: 100%;

        &:focus {
            box-shadow: $boxShadowFocus;
        }

        &.form-control-flat {
            box-shadow: none;
        }

        &.form-control-lg {
            padding: 13px 12px;
        }

        &[disabled] {
            background-color: #f8f8f8;
        }

        &.form-control-without-padding {
            padding: 0px;
           
        }

        &.form-control-without-padding-with-info {
            padding: 0px 30px 0px 0px;
           
        }


        &.form-control-small-padding {
            padding: 3.5px 10px;
        }

        &.form-control-small-padding-with-info {
            padding: 3.5px 30px 3.5px 10px;
        }
    }

    select.form-control {
        appearance: none;
        background: #fff url("../../assets/img/dropdown-arrow.svg") no-repeat;
        background-position: right 10px top 50%;
        padding-right: 25px;

        &:active {
            box-shadow: none;
        }
    }

    .form-control-wrapper {
        position: relative;
        align-items: center;
        display: flex;

        .form-control-info {
            position: absolute;
            right: 10px;
            // top: 50%;
            // margin-top: -10px;
            cursor: pointer;

            .form-control-info-icon {
                width: 16px;
                height: 20px;
                color: $colorPrimary;
                font-size: 20px;
                &.white {
                    color:#fff;        
                }
            }

            .form-control-info-container {
                display: none;
                position: absolute;
                left: 100%;
                top: 50%;
                transform: translate(0, -50%);
                padding: 10px 12px;
                background-color: #fff;
                margin-left: 10px;
                border-radius: $borderRadius;
                box-shadow: $boxShadowPopUp;
                min-width: 30px;
                min-height: 30px;
                z-index: 1;
                width: max-content;
                font: 500 13px/18px $bf;
                cursor: default;

                &:before {
                    right: 100%;
                    top: 50%;
                    transform: translate(0, -50%);
                    position: absolute;
                    content: '';
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 10px 10px 10px 0;
                    border-color: transparent #ffffff transparent transparent;
                }

                &.form-control-info-container-dim {
                    background-color: #535377;
                    color: #fff;

                    &:before {
                        border-color: transparent #535377 transparent transparent;
                    }
                }
            }

            &:hover {
                .form-control-info-container {
                    display: block;
                }
            }
        }
    }

    .form-rich-editor {
        border: 1px solid $borderColor;
        border-radius: $borderRadius;
        display: flex;
        flex-direction: column;

        .form-rich-editor-controls {
            width: 100%;
            background: #f7fafc;
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid $borderColor;

            .rich-editor-title {
                flex-grow: 1;
                font: 500 13px/20px $bf;
                padding: 10px 15px;
            }

            .rich-editor-button-group {
                display: flex;
                flex-direction: row;
                padding: 0 10px;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }

            .rich-editor-buttons {
                display: flex;
                flex-direction: row;
                padding: 0 10px;

                .rich-editor-button {
                    color: #6f747e;
                    font-size: 18px;
                    line-height: 20px;
                    padding: 10px 5px;
                    transition: color 0.4s;

                    &:hover {
                        color: #262626;
                    }
                }
            }
        }

        .form-control {
            background: #fff;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border: none;
            resize: none;
            box-shadow: none;
        }
    }

    .form-error {
        display: block;
        font: 500 12px/20px $bf;
        color: rgb(181, 44, 44);
    }

    .form-checkbox {
        display: inline-flex;
        padding: 10px 0;
        flex-direction: row;
        vertical-align: middle;
        @include cf();

        .checkbox-label {
            vertical-align: middle;
            cursor: pointer;
            margin-bottom: 4px;
            padding: 0;
            display: flex;
            flex-direction: row;

            .checkbox-label-box {
                display: block;
                min-width: 16px;
                width: 16px;
                height: 16px;
                border: 1px solid #ccdaee;
                position: relative;
                float: left;
                border-radius: 3px;
                background-color: #f0f6ff;
                margin: 2px 0;
                transition: 0.2s;

                .mdi {
                    color: #262626;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate3d(-50%, -50%, 0);
                    font-size: 18px;
                    transition: 0.2s;
                    opacity: 0;
                }

                &.checkbox-label-box-shadowless {
                    box-shadow: none;
                }
            }

            .checkbox-label-text {
                font: 600 12px/20px $bf;
                color: $tc;

                .checkbox-label-link {
                    font: inherit;
                    color: $colorPrimary;
                }

                &:first-child {
                    padding: 0 10px 0 0px;
                }

                &:last-child {
                    padding: 0 5px 0 10px;
                }
            }
        }

        input[type="checkbox"],
        input[type="radio"] {
            display: none;
        }

        input[type="radio"]:checked + .checkbox-label,
        input[type="checkbox"]:checked + .checkbox-label {
            .checkbox-label-box {
                background-color: $colorPrimaryLight;
                border-color: $colorPrimaryLight;

                .mdi {
                    opacity: 1;
                    color: #fff;
                }
            }
        }

        &.checkbox-narrow {
            padding-top: 5px;
            padding-bottom: 5px;

            .checkbox-label {
                margin-bottom: 0;
            }
        }
    }

    .form-radio {
        display: inline-block;
        padding: 10px 0;
        padding-right: 15px;
        padding-left: 30px;
        vertical-align: middle;
        width: 100%;
        @include cf();

        .radio-circle {
            background: #EAECF0;
            float: left;
            display: block;
            width: 20px;
            height: 20px;
            border: 1px solid #C7C4C3;
            position: relative;
            margin-right: 5px;
            float: left;
            border-radius: 20px;
            margin-left: -30px;


            &::before {
                content: "";
                @include fill_parent(-2px);
                border: inherit;
                border-radius: inherit;        
                position:absolute;
                margin: auto;            
                width: 12px;
                height: 12px;
                background: $colorPrimary;
                opacity: 0;
                transition: 0.4s;
            }
        }

        .radio-label {
            vertical-align: middle;
            cursor: pointer;
            font: 600 14px/20px $bf;
            padding: 0;
            color: $tc;
            float: left;
        }

        input[type="radio"] {
            display: none;
        }

        input[type="radio"]:checked + .radio-label {
            .radio-circle::before {
                opacity: 1;
            }
        }

        &.radio-narrow {
            padding-top: 5px;
            padding-bottom: 5px;
        }

        &.radio-inline {
            display: inline-block;
            width: auto;
        }
    }

    .form-actions {
        padding-top: $marginBottom;
        border-top: 1px solid $borderColor;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
