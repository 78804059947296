$padding: 80px;
 
&.block-payment-graph {
    display: flex;
    flex-direction: row;
    margin: 0 0 $marginBottom;
    position: relative;

    .graph-col {
        display: flex;
        flex-direction: column;
        flex-basis: 50%;
        padding: 0 $padding;
        z-index: 1;
        position: relative;
        overflow: hidden;
        padding-bottom: 10px;

        .graph-header {
            position: relative;
            &:before,
            &:after {
                content: "";
                display: block;
                position: absolute;
            }
    
            &:before {
                width: 12px;
                height: 12px;
                border-radius: 10px;
                border: 2px solid #C7C4C3;
                background-color: $colorPrimary;
                left: -$padding/2;
                top: 50%;
                transform: translate(-50%, -50%);
            }
    
            &:after {
                background-color: #e6edfb;
                height: 1px;
                left: -$padding;
                right: -$padding;
                top: 50%;
                transform: translate(0, -50%);
                z-index: -1;
            }
        }
        .graph-item {
            position: relative;
    
            &:before,
            &:after {
                content: "";
                display: block;
                position: absolute;
            }
    
            &:before {
                width: 12px;
                height: 12px;
                border-radius: 10px;
                border: 2px solid #C7C4C3;
                background-color: $colorPrimaryLight;
                left: -$padding/2;
                top: 50%;
                transform: translate(-50%, -50%);
            }
    
            &:after {
                background-color: #e6edfb;
                height: 1px;
                left: -$padding;
                right: -$padding;
                top: 50%;
                transform: translate(0, -50%);
                z-index: -1;
            }
        }
    
        .graph-header {
            border: 1px solid $borderColor;
            border-radius: $borderRadius;
            background-color: #fff;
            box-shadow: $boxShadowLabel;
            padding: 10px 20px;
            text-align: center;
            font: 700 16px/20px $bf;
            margin: 0 0 30px;

            &:after {
                right: 0;
                left: -$padding/2;
            }
        }
    
        .graph-item {
            display: flex;
            flex-direction: row;
            border: 1px solid $borderColor;
            border-radius: $borderRadius;
            background-color: #fff;
            box-shadow: $boxShadowLabel;
            padding: 10px 20px;
            margin: 0 0 $marginBottom;
            &[role="button"]{
                cursor: pointer;
            }
          
    
            .graph-item-details {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                padding-right: 20px;
    
                .graph-item-title {
                    color: #6f747e;
                    font: 500 14px/20px $bf;
                    margin: 0 0 10px;
                    @include ellipsis();
    
                    strong {
                        color: #000;
                        font-weight: 700;
                    }
                }
    
                .graph-value {
                    display: flex;
                    flex-direction: row;
                    align-items: center;      
    
                    .graph-currency {
                        width: 25px;
                        height: 25px;
                        margin: 0 10px 0 0;
    
                        img {
                            display: block;
                            width: 100%;
                            height: 100%;
                        }
                    }
    
                    .graph-amount {
                        font: 700 30px/25px $bf;
                        margin-bottom: -5px;
    
                        small {
                            font: 700 14px/25px $bf;
                            color: #c5c5c5;
                        }
    
                        &.graph-amount-default {
                            color: #000;
                        }
                    }
                }
            }
    
            .graph-item-icon {
                display: none;
                flex-direction: column;
                justify-content: center;
    
                .mdi {
                    display: block;
                    color: #fff;
                    background-color: $colorSuccess;
                    width: 25px;
                    height: 25px;
                    line-height: 25px;
                    text-align: center;
                    border-radius: 25px;
                }
            }
    
            &:last-child {
                margin: 0 0 0;
            }

            &:after {
                right: 0;
                left: -$padding/2;
            }
    
            &.graph-item-in {
                .graph-item-icon {
                    display: flex;
    
                    .mdi {
                        background-color: $colorSuccess;
                    }
                }
            }
    
            &.graph-item-out {
                .graph-item-icon {
                    display: flex;
    
                    .mdi {
                        background-color: $colorDanger;
                    }
                }
            }
        }

        &:before, &:after {
            content: "";
            display: block;
            position: absolute;
            background-color: #C7C4C3;  
            width: 2px;
            left: $padding/2;
            top: 21px;
            bottom: 44px;
            z-index: -1;
        }

        // &:after {
        //     left: 50%;
        // }
        
        &:first-child {
            .graph-header {
                &:after {
                    left: -$padding/2;
                }
            }
        }
        
        &:last-child {
            .graph-header {
                &:after {
                    right: 50%;
                }
            }
        }
    }


    .graph-arrow {
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: absolute;
        border: 1px solid $borderColor;
        border-radius: 50px;
        width: 28px;
        height: 28px;
        line-height: 26px;
        left: 50%;
        top: 30px;
        transform: translate(-50%, -50%);
        background-color: #fff;
        z-index: 2;
        top: 20px;

        .mdi {
            font-size: 18px;
            color: $colorPrimary;
        }
    }

    &.block-payment-graph-connect {
        &:before {
            content: '';
            display: block;
            width: 50%;
            // border: 1px solid #e6edfb;
            border-bottom: none;
            border-top: none;
            left: 25%;
            right: 25%;
            top: -20px;
            bottom: 100%;
            position: absolute;
        }
    }
}
