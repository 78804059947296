&.block-app {
    display: flex;
    flex-direction: row;
    cursor: default;
    height: 100vh;
    width: 100vw;
    //min-width: 1200px;
    background-color: #fbfbfb;

    .app-menu {
        display: flex;
        flex-direction: column;
        background-color: #0e0e0e;
        box-shadow: $boxShadowLabel;
        flex-basis: 240px;
        width: 240px;
        min-width: 240px;

        .menu-user {
            display: flex;
            flex-direction: column;
            padding: 20px;

            .menu-user-name {
                color: #fff;
                font: 700 18px/25px $bf;
                padding: 5px 0;
                margin: 0 0 0px;
                position: relative;
                display: flex;
                flex-direction: row;
                margin-right: -10px;
              
          
                .menu-user-name-text {
                    max-width: 195px;
                }

                .menu-user-switch {
                    position: absolute;
                    right: 0;
                    flex-direction: column;
                    justify-content: center;
                    font-size: 20px;
                    opacity: 0.5;
                    cursor: pointer;
                    flex-grow: 1;
                    justify-content: flex-end;
                    // top: 50%;
                    top: 16px;
                    transform: translate(0, -50%);
                    appearance: none;
                    background: none;
                    color: #fff;
                    padding: 0;
                    border: none;

                    &:hover,
                    &.active,
                    &.menu-user-switch-active {
                        opacity: 1;
                    }
                }

                .menu-user-dropdown {
                    position: absolute;
                    z-index: 1;
                    left: 100%;
                    top: 50%;
                    margin-top: 10px;
                    margin-left: -20px;
                    box-shadow: $boxShadowPopUp;
                }
            }

            .menu-user-balance {
                color: #4cd964;
                font: 500 12px/20px $bf;
                margin: 0 0 0px;
            }

            .menu-user-uid {
                color: #919191;
                font: 500 12px/20px $bf;
                margin: 0 0 0px;
            }

            .menu-user-email {
                color: #ffffff;
                font: 500 12px/20px $bf;
                margin: 0 0 0px;
            }
        }

        .menu-items {
            display: flex;
            flex-direction: column;
            padding-bottom: 20px;
            flex-grow: 1;
            background-color: inherit;
            @include webkit_scrollbar(#9e9fa1, inherit);

            .menu-item {
                display: flex;
                flex-direction: column;
                width: 100%;
                position: relative;
                background-color: #0e0e0e;
                color: rgba($color: #fff, $alpha: 0.6);

                .menu-item-link {
                    display: flex;
                    flex-direction: row;
                    padding: 10px 20px;
                    cursor: pointer;

                    .menu-item-icon {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        width: 30px;
                        opacity: 0.6;
                        padding: 1px 10px 1px 0px;

                        img {
                            display: block;
                            height: 18px;
                        }
                    }

                    .menu-item-label {
                        display: flex;
                        flex-direction: row;
                        flex-grow: 1;
                        font: 500 15px/20px $bf;

                        .menu-item-notes {
                            background-color: #dd1a1e;
                            border-radius: 10px;
                            min-width: 20px;
                            max-width: 30px;
                            min-height: 20px;
                            line-height: 20px;

                            display: flex;
                            align-items: center;
                            text-align: center;        
                            justify-content: center;
                            align-self: center;

                            display: block;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;                   
                        
                            padding: 0px 4px;
                  
                            color: #fff;
                            font: 500 12px/20px $bf;
                            margin-left: 7.5px;
                        }
                    }

                    .menu-item-caret {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        img {
                            display: flex;
                        }
                    }
                }

                .menu-item-dropdown {
                    display: flex;
                    flex-direction: column;

                    .menu-item-dropdown-item {
                        display: flex;
                        flex-direction: row;
                        padding: 9px 10px 9px 50px;
                        font: 400 13px/20px $bf;
                        background-color: inherit;
      
                        transition: 0.4s;
                        cursor: pointer;
                        align-items: flex-start;

                        &:hover {
                            background-color: #f37749;
                        }
                        &.active {
                            background-color: #f37749;
                        }


                        .menu-item-notes {
                            background-color: #dd1a1e;
                            border-radius: 10px;
                            min-width: 20px;
                            max-width: 30px;
                            min-height: 20px;
                            line-height: 20px;

                            display: flex;
                            align-items: center;
                            text-align: center;        
                            justify-content: center;
                            align-self: center;

                            display: block;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;                   
                        
                            padding: 0px 4px;
                  
                            color: #fff;
                            font: 500 12px/20px $bf;
                            margin-left: 7.5px;
                        }
                       
                    }
                }

                .menu-item-popup {
                    position: absolute;
                    left: 100%;
                    bottom: -30px;
                    padding-left: 20px;
            
                

                    .menu-item-popup-triangle {           
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 10px 10px 10px 0;
                        border-color: transparent #ffffff transparent transparent;
                        bottom: 40px;
                        left: 21px;
                        transform: translate(-100%);
                        position: absolute;
                        z-index: 0;
                    }
                }

                &.active,
                &.menu-item-active {
                    background-color: #222222;
                    color: #fff;

                    .menu-item-link {
                        .menu-item-icon {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    .app-container {
        display: flex;
        flex-direction: column;
        // flex-basis: 0;
        // flex-grow: 1;
        flex: 1 1;
        overflow: hidden;

        .app-header {
            border-bottom: 1px solid #eaecf0;
            background-color: #fff;
            box-shadow: $boxShadowLabel;
            padding: 20px;

            .header-title {
                font: 700 26px/38px $bf;
                text-transform: uppercase;
                margin-right: 40px;

                * {
                    font: inherit;
                    font-weight: 400;
                }
            }

            .header-menu {
                display: flex;
                flex-direction: row;

                .header-menu-item {
                    display: flex;
                    flex-direction: row;
                    font: 500 16px/22px $bf;
                    padding: 28px 0;
                    margin: -20px 0;
                    margin-right: 40px;
                    position: relative;

                    &::after {
                        content: "";
                        display: block;
                        width: 100%;
                        height: 2px;
                        background-color: $colorPrimary;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        opacity: 0;
                        transition: opacity 0.4s;
                    }

                    &.active,
                    &.header-menu-item-active {
                        color: $colorPrimary;

                        &:after {
                            opacity: 1;
                        }
                    }

                    &:hover {
                        color: $colorPrimary;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }

                &:first-child {
                    padding-left: 20px;
                }
            }

            .header-breadcrumbs {
                display: flex;
                flex-direction: row;

                .header-breadcrumb {
                    display: flex;
                    flex-direction: row;
                    font: 500 26px/22px $bf;
                    padding: 28px 0;
                    margin: -20px 0;
                    margin-right: 40px;
                    position: relative;
                    color: #919191;
                    position: relative;

                    .header-breadcrumb-arrow {
                        margin-right: -30px;
                        flex-direction: column;
                        justify-content: center;
                        height: 100%;
                        color: #c2c2c2;
                        pointer-events: none;
                        font-size: 20px;
                    }

                    &:hover {
                        color: $colorPrimary;
                    }

                    &.active,
                    &.header-breadcrumb-active {
                        font: 700 26px/22px $bf;
                        color: #000000;
                        cursor: default;
                        pointer-events: none;

                        &:after {
                            opacity: 1;
                        }

                        &:hover {
                            color: #000;
                        }
                    }

                    &:last-child {
                        margin-right: 0;

                        .header-breadcrumb-arrow {
                            display: none;
                        }
                    }
                }
            }

            .app-header-buttons {
                display:flex;
                flex-direction:row; 
                padding:5px
            }

            .app-header-buttons > div {
                flex:1 1 auto;
                margin:5px;
            }

            .menu-item {
                display: flex;
                flex-direction: column;
                // width: 100%;
                position: relative;
                color: #080808;

                .menu-item-link {
                    display: flex;
                    flex-direction: row;
                    padding: 10px 10px;
                    cursor: pointer;

                    .menu-item-icon {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        // width: 30px;
                        opacity: 1;
                        padding: 1px 10px 1px 0px;

                        img {
                            display: block;
                            height: 16px;
                        }
                       
                        .menu-item-notes {
                            position: absolute;
                            top: -6px;
                            right: 4px;
                            background-color: #dd1a1e;
                            border-radius: 10px;
                            min-width: 20px;
                            max-width: 30px;
                            min-height: 20px;
                            line-height: 20px;

                            display: flex;
                            align-items: center;
                            text-align: center;        
                            justify-content: center;
                            align-self: center;

                            display: block;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;                   
                        
                            padding: 0px 4px;
                  
                            color: #fff;
                            font: 500 12px/20px $bf;
                           
                        }
                    }
                    .menu-item-label {
                        display: flex;
                        flex-direction: row;
                        flex-grow: 1;
                        font: 400 14px $bf;           
                        margin-top: 2px;
                    }
                   
                }

                &.active,
                &.menu-item-active {                   
                    color: $colorPrimary;

                    .menu-item-link {
                        .menu-item-icon {
                            opacity: 0.6;
                        }
                    }
                }
            }
        }

        .app-content {
            display: flex;
            flex-direction: row;
            padding: 20px;
            //flex-grow: 1;
            flex: 1 1;
            overflow: auto;
            @include webkit_scrollbar(#9e9fa1, inherit);

            .app-aside,
            .app-body {
                height: fit-content;

                &:first-child:not(:last-child) {
                    padding-right: 20px;
                }
            }

            .app-body {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
            }

            .app-aside {
                display: flex;
                flex-direction: column;
                flex-basis: 300px;
                width: 300px;

                &.app-aside-wide {
                    flex-basis: 600px;
                    width: 600px;
                }
            }
           
        }

        .app-currencies-footer {     
            background-color: #fff;
            box-shadow: inset 0px 1px 0px #EAECF0;
            display: flex;
            width: 100%;
            overflow-y: hidden;
            overflow-x: auto;
            @include webkit_scrollbar(#9e9fa1, inherit);

            .app-currencies-footer-wrapper {
                display: flex;
                align-items: center;
                width: fit-content;
            }

            .currency-item {
                display: flex;
                flex-direction: row;            
                padding: 16px 25px;
               

                .mdi {
                    display: flex;
                    font-size: 16px;
                    line-height: inherit;
                    &.increase {
                        color: #66CD77;
                    }
                    &.decrease {
                        color: #D13B3B;
                    }
                }

                // .currency-item-icon {
                //     display: flex;
                //     flex-direction: row;
                //     justify-content: center;
                //     // width: 30px;
                //     // opacity: 1;
                //     padding: 1px 10px 1px 0px;

                //     img {
                //         display: block;
                //         height: 16px;
                //     }
                    
                // }
                .currency-item-label {
                    display: flex;
                    flex-direction: row;
                    flex-grow: 1;
                    font: 400 14px $bf;           
                    margin-top: 2px;
                    text-transform: uppercase;
                    margin-right: 10px;
                    &.value {
                        margin-right: 5px;
                    }
                }
               
            }

            .dot {
                width: 4px;
                height: 4px;
                border-radius: 2px;
                margin-top: 7px;
                /* #CCCFD5 */
                background: #CCCFD5;
            }
        }
    }
}
