&.block-media-picker {
    display: flex;
    flex-direction: row;
    margin: 0 0 $marginBottom;

    .picker-media {
        display: flex;
        flex-direction: column;
        width: 100px;
        height: 100px;
        padding: 5px;
        background: #fff;
        box-shadow: $boxShadowLabel;
        border-radius: 100px;
        margin-right: 20px;
        transition: box-shadow 0.4s;

        .picker-media-abbr {
            border: 4px solid #e1edff;
            height: 90px;
            width: 90px;
            border-radius: inherit;
            text-align: center;
            font: 700 24px/90px $bf;
            color: $colorPrimary;
        }

        .picker-media-image {
            display: block;
            border-radius: 90px;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        .mdi {
            display: flex;
            flex-direction: row;
            justify-content: center;
            color: $colorDefault;
            line-height: 90px;
            text-align: center;
            font-size: 30px;
            height: 90px;
            width: 90px;
            border: 2px solid lighten($color: $colorDefault, $amount: 20%);
            border-radius: inherit;
        }

        &[for],
        &a {
            cursor: pointer;
        }
    }

    .picker-details {
        display: flex;
        flex-direction: column;
        padding: 20px 0;

        .picker-details-title {
            display: flex;
            font: 700 24px/30px $bf;
        }

        .picker-details-subtitle {
            display: flex;
            font: 400 14px/30px $bf;
            color: #919191;
        }
    }
}
