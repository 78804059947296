&.block-read-more {
    padding: 15px 20px;
    display: flex;
    flex-direction: row; 
    background-color: #fefaf0; 
    border: 1px solid #dfdbcf;
    margin: 0 0 $marginBottom;
    border-radius: $borderRadius;

    .read-icon {
        font-size: 20px;
        margin-right: 10px;
        line-height: 20px;
        color: $colorPrimary;
    }

    .read-text {
        font: 400 14px/20px $bf;
        display: flex;
        flex-grow: 1;

        a {
            font: inherit;
            font-weight: 500;
            color: $colorPrimary;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .read-actions {
        display: flex;
        flex-direction: row; 

        .read-action {
            font: 500 14px/20px $bf;
            color: $colorPrimary;
        }
    }
}