.pagination {
    width: 100%;
    display: flex;
    flex-direction: row;

    .pagination-overview {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        font: 500 14px/30px $bf;
        color: #2f2e43;
        align-items: center;
        align-content: center;
        margin-left: 15px;

        &.pagination-overview-center {
            margin-left: 0px;
            justify-content: center;
        }

        .pagination-overview-per_page {
            padding: 0 10px 0 0;

            .form-control {
                line-height: 20px;
                padding: 5px 10px;
                width: 50px;
                min-width: 60px;
            }
        }
    }

    .pagination-list {
        display: flex;
        flex-direction: row;
        &.center {      
            justify-content: center;
        }
    }

    .pagination-item {
        display: flex;
        font: 500 13px/30px $bf;
        color: #2f2e43;
        border: 1px solid $borderColor;
        border-radius: $borderRadius;
        margin-right: 5px;
        padding: 0 7.5px;
        cursor: pointer;
        background: #fff;
        transition: color 0.4s, background-color 0.4s, border-color 0.4s;
        min-width: 30px;
        justify-content: center;

        .mdi {
            font-size: 18px;
        }

        &:hover {
            background-color: lighten($color: $colorPrimary, $amount: 10%);
            border-color: lighten($color: $colorPrimary, $amount: 10%);
            color: #000;
        }

        &.active,
        &:active,
        &:active,
        &[active] {
            background-color: $colorPrimary;
            border-color: $colorPrimary;
            color: #000;
        }

        &.disabled,
        &:disabled,
        &[disabled] {
            pointer-events: none;
            opacity: 0.75;
            background-color: #f7fafc;
            color: #9fa4b3;
        }

        &:last-child {
            margin-right: 0;
        }
    }


    .dots-pagination-item {
        display: flex;
        font: 500 13px/30px $bf;
        color: #2f2e43;
        border: 1px solid $borderColor;
        border-radius: $borderRadius;
        margin-right: 5px;
        padding: 0 7.5px;
 
        background: #fff;
        transition: color 0.4s, background-color 0.4s, border-color 0.4s;
        min-width: 30px;
        justify-content: center;

   
        box-sizing: border-box;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    }
}
