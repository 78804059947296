&.block-payment {
    display: flex;
    flex-direction: column;
    margin: 0 0 $marginBottom;
    color: $tc;
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    background-color: #fff;
    box-shadow: $boxShadowLabel;

    .payment-overview {
        display: flex;
        flex-direction: column;
        padding: 20px;
        border-bottom: 1px solid $borderColor;
        position: relative;
       

        .payment-title {
            display: flex;
            flex-direction: row;
            font: 500 14px/20px $bf;
            margin: 0 0 10px;
        }

        .payment-value {
            display: flex;
            flex-direction: row;
            align-items: center;

            .payment-currency {
                width: 25px;
                height: 25px;
                margin: 0 10px 0 0;

                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }

            .payment-amount {
                font: 700 30px/25px $bf;
                margin-bottom: -5px;

                small {
                    font: 700 14px/25px $bf;
                    color: #c5c5c5;
                }

                &.payment-amount-default {
                    color: #000;
                }
            }

            &.payment-value-justify {
                justify-content: space-between;
                width: 100%;
            }
        }

        .payment-overview-icon {
            color: #fff;
            background-color: #000;
            width: 25px;
            height: 25px;
            border-radius: 25px;
            text-align: center;
            line-height: 25px;
            position: absolute;
            right: 20px;
            bottom: 20px;
        }

        &:last-child {
            border-bottom: none;
        }
    }

    .payment-details {
        display: flex;
        flex-direction: column;
        padding: 10px 20px;

        .payment-details-heading {
            display: flex;
            flex-direction: row;
            padding: 10px 0;

            .payment-details-title {
                display: flex;
                flex-direction: column;
                font: 700 16px/25px $bf;
                flex-grow: 1;
            }

            .payment-details-toggle {
                display: flex;
                flex-direction: row;
                font: 500 13px/25px $bf;
                color: $colorPrimary;
                cursor: pointer;

                .mdi {
                    font-size: 15px;
                    line-height: 25px;
                }
            }
        }

        .payment-details-list {
            display: flex;
            flex-direction: column;

            .payment-details-item {
                display: flex;
                flex-direction: row;
                border-bottom: 1px solid $borderColor;
                padding: 10px 0;

                .payment-details-key,
                .payment-details-value {
                    display: flex;
                    flex-direction: column;
                    flex-basis: 50%;
                    flex-grow: 1;
                    color: #919191;
                    padding-right: 20px;
                }

                .payment-details-key {
                    color: #919191;
                    font: 400 13px/20px $bf;
                }

                .payment-details-value {
                    color: #000000;
                    font: 500 13px/20px $bf;
                }

                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }

    &.block-payment-in {
        .payment-overview {
            .payment-amount {
                color: $colorSuccessDark;
            }

            .payment-overview-icon {
                background-color: $colorSuccess;
            }
        }
    }

    &.block-payment-out {
        .payment-overview {
            .payment-amount {
                color: $colorDanger;
            }

            .payment-overview-icon {
                background-color: $colorDanger;
            }
        }
    }
}
