&.block-external-connections {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -10px;

    .external-connection {
        padding: 0 10px 0;
        margin: 0 0 20px;
        flex-basis: (100%/5);
        width: (100%/6);
        min-width: 200px;
        max-width: 260px;
        cursor: pointer;
    }
    

    .external-connection-container {
        position: relative;
        display: flex;
        flex-direction: column;
        padding-top: 20px;

        background: #fff;
        border: 1px solid $borderColor;
        // border-radius: $borderRadius / 2;
        box-shadow: $boxShadow;

        &:hover {
            background-color: rgba($color: #fff, $alpha: .5);
            box-shadow: $boxShadowFocus;
        }

        .external-connection-logo {
            height: 92px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .external-connection-logo-img {
                display: block;
                margin: 0 auto;
                max-width: 110px;
                max-height: 100px;
            }
        }

        .external-connection-name {
            font: 700 16px/24px $bf;
            text-align: center;
            @include ellipsis();
            margin-bottom: 19px;
        }

        .external-connection-button {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            border: 1px solid $borderColor;
            border-top-width: 1px;
            border-left-width: 0px;
            border-right-width: 0px;
            border-bottom-width: 0px;  
            height: 40px;
            .external-connection-button-left {
                flex-grow: 1;
                flex-basis: 50%;
                width: 50%;
                border: 1px solid $borderColor;
                border-top-width: 0px;
                border-left-width: 0px;
                border-right-width: 1px;
                border-bottom-width: 0px;  
            }
            .external-connection-button-right {
                flex-grow: 1;
                flex-basis: 50%;
                width: 50%;
            }

        }

        .external-connection-check {
            position: absolute;
            right: 12px;
            top: 12px;
            width: 18px;
            height: 18px;
            border-radius: 18px;
            background-color: #f0f6ff;
            border: 1px solid #ccdaee;
            // background-color: $colorPrimary;

            .mdi {
                display: none;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                color: #fff;
            }
        }

        // &:hover {
        //     border-color: $colorPrimary;
        //     .external-connection-check {
        //         border-color: $colorPrimary;
        //         background-color: $colorPrimary;

        //         .mdi {
        //             display: block;
        //         }
        //     }
        // }
    }

    .external-connection-active {
        border-color: $colorSuccess;

        .external-connection-container {
           

            .external-connection-check {
                border-color: #399d0a;
                background-color: #399d0a;

                .mdi {
                    display: block;
                }
            }
            .external-connection-pending {
                position: absolute;
                right: 12px;
                top: 12px;
                border-color: #f0df1b;
                background-color: #f0df1b;
                width: 18px;
                height: 18px;
                border-radius: 18px;
            }
        }
    }
}
