&.block-pay-modal {
  iframe {
    width: 100%;
    height: 100%;
    border: 0 solid black;
  }
  
  .field-wrapper {
    width: 200px;
    height: 30px;
  }
  
  .fake-field {
    align-items: center;
    background-color: #FFF;
    border-radius: 4px;
    border: 1px solid transparent;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    display: flex;
    height: 42px;
    margin-bottom: 20px;
    width: 316px;
  }
  
  #field-wrapper-name,
  #field-wrapper-tags {
    width: 100%;
    height: 100%;
    padding-left: 10px;
  }
  
  #field-wrapper-address_line1 {
    width: 115px;
    height: 100%;
    padding-left: 10px;
  }
  
  #field-wrapper-address_line2,
  #field-wrapper-address_postal_code {
    width: 55px;
    height: 100%;
  }
  
  #field-wrapper-address_city {
    padding-left: 6px;
    width: 100px;
    height: 100%;
  }
  
  #field-wrapper-address_region {
    width: 25px;
    height: 100%;
  }
  
  #brand {
    background-color: #333;
    border-radius: 3px;
    color: #fff;
    display: block;
    font-family: arial;
    font-size: 12px;
    margin: 3px;
    overflow: hidden;
    padding: 1px 3px;
    text-align: center;
    width: 69px;
  }
  
  #field-wrapper-number {
    width: 100%;
    height: 100%;
    padding-left: 10px;
  }
  
  #field-wrapper-expiration_date,
  #field-wrapper-security_code {
    width: 100%;
    height: 100%;
    padding-left: 10px;
  }

}
