.table {
    display: block;
    margin: 0 0 $marginBottom;

    table {
        border-collapse: collapse;
        width: 100%;
        text-align: left;
        border: 1px solid $borderColor;
        box-shadow: $boxShadowLabel;

        tbody tr:hover td {
            background-color: #f5f5f5;
          }

        tr {
            th {
                button {
                    color: #262626;
                    font: 700 12.5px/18px $bf;
                    cursor: pointer;
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    border: 0;
                    padding: 0;
                    margin: 0;
                    background: transparent;
                }
                color: $tc;
                font: 700 12px/20px $bf;
                padding: 15px 15px;
                border-bottom: 1px solid $borderColor;
                background-color: #f2f2f2;
                white-space: nowrap;

                .table-th-order-by {
                    min-width: 150px;
                    font: 500 11px/20px $bf;
                    display: inline-flex;
                    justify-content: center flex-start;
                    cursor: pointer;
                    transition: color 0.4s;

                    &:hover {
                        color: $colorPrimary;
                    }
                }

                .mdi {
                    font-size: 16px;
                    min-width: 20px;
                    color: #6f747e;
                }

                &.active {
                    .table-th-order-by {
                        font-weight: 700;
                    }
                }

                &.narrow,
                &.th-narrow {
                    width: 1%;
                }

                &:first-child {
                    padding-left: 25px;
                }

                &:last-child {
                    padding-right: 15px;
                }
            }

            td {
                color: #262626;
                font: 500 12.5px/18px $bf;
                padding: 15px 15px;
                border-bottom: 1px solid $borderColor;        
                transition: background-color 0.4s;
                background-color: #fff;
                button {
                    color: #262626;
                    font: 500 12.5px/18px $bf;
                    cursor: pointer;
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    border: 0;
                    padding: 0;
                    margin: 0;
                    background: transparent;
                }

                .table-user-compact {
                    display: block;

                    .table-user-media {
                        width: 18px;
                        height: 18px;
                        float: left;
                        margin-right: 5px;
                        position: relative;

                        .table-user-media-abbr {
                            width: 18px;
                            height: 18px;
                            display: block;
                            color: #fff;
                            border-radius: 18px;
                            text-align: center;
                            font: 700 11px/18px $bf;
                            background-color: $colorPrimary;
                        }

                        .table-user-media-image {
                            border-radius: 18px;
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                            border: 1px solid $borderColor;
                            background-color: $colorPrimary;
                        }
                    }

                    .table-user-name {
                        font: 700 12px/18px $bf;
                        color: $colorDark;
                        .overflow {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 1; /* number of lines to show */
                            -webkit-box-orient: vertical;
                        }
                    }
                }

                .table-currency {
                    display: block;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .table-currency-media {
                        width: 18px;
                        height: 18px;
                        float: left;
                        margin-right: 5px;
                        position: relative;

                        .table-currency-media-abbr {
                            width: 18px;
                            height: 18px;
                            display: block;
                            color: #fff;
                            border-radius: 18px;
                            text-align: center;
                            font: 700 11px/18px $bf;
                            background-color: $colorPrimary;
                        }

                        .table-currency-media-image {
                            border-radius: 18px;
                            width: 100%;
                            border: 1px solid $borderColor;
                            background-color: $colorPrimary;
                        }
                    }

                    .table-currency-value {
                        font: 500 12px/18px $bf;
                        color: $tc;
                        margin-bottom: -2.5px;
                    }
                }

                .td-icon {
                    font-size: 18px;
                    line-height: 18px;
                    margin-right: 5px;

                    &:last-child {
                        margin-right: 0;
                    }

                    &.td-icon-sm {
                        font-size: 16px;
                        line-height: 16px;
                    }
                }

                .td-icon-text {
                    line-height: 18px;
                    vertical-align: middle;

                    .td-icon {
                        vertical-align: middle;
                    }
                }

                .td-drown {
                    margin: -2px 0;

                    &.td-drown-md {
                        margin: -4px 0;
                    }

                    &.td-drown-lg {
                        margin: -6px 0;
                    }
                }

                .td-heading {
                    font: 700 12px/20px $bf;
                    color: #262626;
                    display: flex;
                    flex-direction: row;

                    .mdi {
                        color: $colorPrimary;
                        font-size: 15px;

                        &.start {
                            align-self: flex-start;
                            margin-right: 5px;
                        }

                        &.end {
                            align-self: flex-end;
                            margin-left: 5px;
                        }
                    }
                }

                .td-status {
                    display: block;
                    position: relative;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    margin-right: 5px;
                    float: left;

                    &.none {
                        display: none;
                    }

                    &.td-status-success {
                        background: $colorSuccessDark;
                        border: 4px solid #cbf4c9;
                    }

                    &.td-status-default {
                        background: #000000;
                        border: 4px solid #e3e8ed;
                    }

                    &.td-status-danger {
                        background: $colorDanger;
                        border: 4px solid #fdf2e9;
                    }
                }

                &.td-narrow {
                    width: 0.1%;
                }

                &:first-child {
                    padding-left: 25px;
                }

                &:last-child {
                    padding-right: 15px;
                }
            }

            td,
            th {
                .form-checkbox {
                    padding: 0;

                    .checkbox-label {
                        margin: 0;
                    }
                }

                .button.button-menu {
                    padding: 0;
                }
            }

            &:last-child {
                td {
                    border-bottom: none;
                }
            }

            &.tr-compact,
            &.tr-overview {
                th {
                    padding-top: 5px;
                    padding-bottom: 5px;
                }

                td {
                    background-color: #fff;
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
            }

            &:nth-child(even),
            &:nth-child(odd) {
                &.tr-clickable,
                &.tr-selectable,
                &[ng-click],
                &[data-href],
                &[ui-sref] {
                    cursor: pointer;

                    &:hover {
                        td {
                            background-color: #f7fafc;
                        }
                    }
                }
            }
        }

       
    }

    &.table-fill {
        margin: -25px -25px 0;

        table {
            margin-bottom: -25px;
        }
    }

    &.table-stripped {
        table {
            tr:nth-child(even) {
                td {
                    background-color: #fcfdfd;
                }
            }
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
}
