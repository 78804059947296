// clear Fix - for floating blocks
@mixin cf(){
    *zoom: 1;

    &:before, &:after{
        content: " ";
        display: table;
    }

    &:after{
        clear: both;
    }
}

// fill parrent block
@mixin fill_parent($offset: 0px, $position: absolute){
    position: $position;
    top: $offset;
    right: $offset;
    bottom: $offset;
    left: $offset;
}

// ellipsis text
@mixin ellipsis {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

// ellipsis text
@mixin visible_on_hover($item, $timeout: .3s) {
    #{$item}, #{$item} * {
        opacity: 0;
        visibility: hidden;
        transition: opacity $timeout / 3, visibility $timeout / 3 linear;
    }

    &:hover {
        #{$item}, #{$item} * {
            opacity: 1;
            visibility: visible;
            transition: opacity $timeout, visibility $timeout linear;
        }
    }
}

// ellipsis text
@mixin visible_on_toggle($item, $class, $timeout: .3s) {
    #{$item}, #{$item} {
        opacity: 0;
        visibility: hidden;
        transition: opacity $timeout / 3, visibility $timeout / 3 linear;
    }

    &#{$class} {
        #{$item} {
            opacity: 1;
            visibility: visible;
        }
    }
}

@mixin webkit_scrollbar($color: $colorPrimaryLight, $background: #f3f4f5) {
    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    &::-webkit-scrollbar-track {
        background: $background;
        border: 1px solid transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: $color;
        border-radius: 4px;
    }
}

@mixin webkit_scrollbar_gray($color: #9E9FA1, $background: #f3f4f5) {
    @include webkit_scrollbar($color, #f3f4f5);
}