/*==========  Style Sheet - Placeholders  ==========*/
::-webkit-input-placeholder {
    color: $ptc;
    font: inherit;
    line-height: 20px;
}

::-moz-placeholder {
    color: $ptc;
    font: inherit;
    line-height: 20px;
    opacity: 1;
}

:-moz-placeholder {
    color: $ptc;
    font: inherit;
    line-height: 20px;
    opacity: 1;
}

:-ms-input-placeholder {
    color: $ptc;
    font: inherit;
    line-height: 20px;
}
