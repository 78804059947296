&.block-title {
    display: flex;
    flex-direction: row;
    font: 700 16px/30px $bf;
    margin: 0 0 ($marginBottom / 2);

    .block-title-wrapper {
        display: flex;
        flex-direction: column;
        font-size: 24px;

        .block-title-subtitle {
            display: block;
            font: 500 14px/20px $bf;
            color: #919191;

            a {
                display: inline;
                color: $colorPrimary;
                font: inherit;
                font-weight: 600;
                transition: color 0.4s;

                &:hover {
                    color: $colorPrimaryLight;
                    text-decoration: underline;
                }
            }
        }
    }

    .block-title-actions {
        display: flex;
        justify-content: flex-end;
        flex-grow: 1;
        margin: -1px 0;
        justify-self: flex-start;
        align-self: flex-start;
    }
}
