/*==========  Style Sheet - Vars  ==========*/

// base font
$bf:               'Azo Sans', 'Roboto', arial, sans-serif;

// text color
$tc:                #202531;

// base color
$bc:                #d13b3b;

// placeholder text color
$ptc:               #919191;

// base color 
$colorPrimary:      #f37749;
$colorPrimaryLight: #fd7f51;
$colorPrimaryDark: #e24f1a;

$colorDanger: #b31313;
$colorDangerLight: #fdf2e9;
$colorSuccess: #66cd77;
$colorSuccessDark: #18a38a;
$colorWarning: #ffa500;
$colorDefault: silver;
$colorDark: #0C161D;


$boxShadow: 1px 2px 3px 1px rgba(0,0,0,.075);
$boxShadowFocus: 1px 5px 20px 2px rgba(0,0,0,.075);
$boxShadowLabel: 1px 2px 3px 0px rgba(0,0,0,.075);
$boxShadowPopUp: 1px 2px 25px 2px rgba(0,0,0,.15);

$wrapperPadding: 30px;
$marginBottom: 20px;

$borderRadius: 5px;
$borderColor: #eaecf0;

$bodyBackgroundColor: #eaf0f6;