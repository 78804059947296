&.block-report-pane {
    display: flex;
    flex-direction: column;
    margin: 0 0 $marginBottom;
    color: $tc;
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    background-color: #fff;
    padding: 15px 20px;
    box-shadow: $boxShadowLabel;
    cursor: pointer;

    &.without-shadow {
        box-shadow: none;
    }
    
    .report-title {
        display: flex;
        flex-direction: row;
        font: 700 14px/20px $bf;
    }

    .report-members {
        display: flex;
        flex-direction: row;
        color: $colorPrimary;
        font: 500 12px/20px $bf;

        .mdi {
            font-size: 15px;
            line-height: 20px;
            margin-right: 2.5px;
        }

        .report-members-label {
            font: 500 12px/20px $bf;
            color: $colorDark;
        }
    }
}
