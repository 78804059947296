@import 'includes/vars';
@import 'includes/resetter';
@import 'includes/helpers';
@import 'includes/fonts';
@import 'includes/placeholders';
@import 'includes/third-party';

// COMMON
@import 'includes/common';

// DEV PAGES
@import 'includes/dev-pages';

// Components
@import 'components/grid.scss';
@import 'components/flex-grid.scss';
@import 'components/forms.scss';
@import 'components/labels.scss';
@import 'components/buttons.scss';
@import 'components/tables.scss';
@import 'components/pagination.scss';

.block {
    @import 'blocks/block-app.scss';
    @import 'blocks/block-auth.scss';
    @import 'blocks/block-circle-pane.scss';
    @import 'blocks/block-current-plan-cards.scss';
    @import 'blocks/block-current-plan.scss';
    @import 'blocks/block-notifications-popup.scss';
    @import 'blocks/block-overview_cards.scss';
    @import 'blocks/block-pagination.scss';
    @import 'blocks/block-pay-modal.scss';
    @import 'blocks/block-payment-graph.scss';
    @import 'blocks/block-payment.scss';
    @import 'blocks/block-receipt-pane.scss';
    @import 'blocks/block-report-pane.scss';
    @import 'blocks/block-title.scss';
    @import 'blocks/block-users.scss';
    @import "blocks/block-card-service.scss";
    @import "blocks/block-external-connections.scss";
    @import "blocks/block-invite-modal.scss";
    @import "blocks/block-media-picker.scss";
    @import "blocks/block-merchant.scss";
    @import "blocks/block-modal-right.scss";
    @import "blocks/block-overview-list.scss";
    @import "blocks/block-permissions.scss";
    @import "blocks/block-read-more.scss";
    @import "blocks/block-window.scss";
}

// dropdowns
.dropdown {
    @import "dropdowns/dropdown-permissions.scss";
    @import "dropdowns/dropdown-user_selector.scss";
}