&.block-merchant {
    margin: 0 0 $marginBottom;
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    background-color: #fff;
    box-shadow: $boxShadowLabel;
    display: flex;
    flex-direction: column;

    .merchant-note,
    .merchant-create,
    .merchant-overview {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid $borderColor;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .merchant-create {
        padding: 15px 20px;
        flex-direction: row;

        .merchant-create-icon {
            padding: 9px 0px;
            margin-right: 10px;

            img {
                display: block;
                height: 20px;
            }
        }

        .merchant-create-title {
            display: flex;
            padding: 9px 0px;
            margin-right: 20px;
            font: 700 16px/20px $bf;
            flex-grow: 1;
        }
    }

    .merchant-overview {
        padding: 15px 20px;
        
        .merchant-icon {
            display: flex;
            margin-right: 10px;

            img {
                display: block;
                width: 100%;
            }
        }

        .merchant-details {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            padding: 0 0;
            
            .merchant-title {
                color: #202531;
                font: 700 12px/20px $bf;
                margin: 0 0 0;
            }

            .merchant-subtitle {
                font: 500 12px/18px $bf;
                color: #6f747e;
                margin: 0 0 0;
            }
        }
    }

    .merchant-note {
        padding: 15px 20px;
        font: 400 12px/20px $bf;
        color: #6f747e;
        background-color: #fbfbfb;

        .merchant-note-icon {
            display: block;
            height: 18px;
            margin-right: 10px;
        }
    }
}