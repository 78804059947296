$regularButtonLineHeight: 20px;
$regularButtonUpDownPadding: 7px;
$regularButtonHeight: $regularButtonLineHeight + ($regularButtonUpDownPadding * 2);

.button {
    display: flex;
    padding: 8px 15px;
    font: 500 14px/20px $bf;
    border-radius: $borderRadius;
    background: none;
    border: 1px solid transparent;
    cursor: pointer;
    width: fit-content;
    transition: box-shadow .4s, color .4s, background-color .4s, border-color .4s;
    background-position: 50% 50%;
    background-size: cover;
    margin: 0;

    .mdi {
        display: flex;
        font-size: 16px;
        line-height: inherit;

        &.start {
            margin-right: 5px;
            margin-left: -2.5px;
            float: left;
        }

        &.end {
            margin-left: 5px;
            margin-right: -2.5px;
            float: right;
        }
    }

    .primary {
        color: $colorPrimary;
    }

    sup {
        font: 500 8px $bf;
    }

    .button-icon-img {
        height: 26px;
        margin: -3px 0;

        &.start {
            margin-right: 5px;
            margin-left: -2.5px;
            float: left;
        }

        &.end {
            margin-left: 5px;
            margin-right: -2.5px;
            float: right;
        }
    }

    &.button-primary {
        background-color: $colorPrimary;
        border-color: $colorPrimary;
        color: #fff;

        &:hover {
            background-color: #fff;
            color: $colorPrimary;
            box-shadow: $boxShadow;
        }

        &.button-primary-outline {
            background-color: transparent;
            border-color: $colorPrimary;
            color: $colorPrimary;

            &:hover {
                background-color: $colorPrimary;
                border-color: $colorPrimary;
                color: #fff;
            }
        }
    }

    &.button-dark {
        background-color: $colorDark;
        border-color: $colorDark;
        color: #fff;

        &:hover {
            background-color: #fff;
            color: $colorDark;
            box-shadow: $boxShadow;
        }

        &.button-dark-outline {
            background-color: transparent;
            border-color: $colorDark;
            color: $colorDark;

            &:hover {
                background-color: $colorDark;
                border-color: $colorDark;
                color: #fff;
            }
        }
    }

    &.button-danger {
        background-color: $colorDanger;
        border-color: $colorDanger;
        color: #fff;

        &:hover {
            background-color: #fff;
            color: $colorDanger;
            box-shadow: $boxShadow;
        }

        &.button-danger-outline {
            background-color: transparent;
            border-color: $colorDanger;
            color: $colorDanger;

            &:hover {
                background-color: $colorDanger;
                border-color: $colorDanger;
                color: #fff;
            }
        }
    }

    &.button-success {
        background-color: $colorSuccess;
        border-color: $colorSuccess;
        color: #fff;
        font-weight: 600;

        &:hover {
            background-color: #fff;
            color: $colorSuccess;
            box-shadow: $boxShadow;
        }

        &.button-primary-outline {
            background-color: transparent;
            border-color: $colorSuccess;
            color: $colorSuccess;

            &:hover {
                background-color: $colorSuccess;
                border-color: $colorSuccess;
                color: #fff;
            }
        }
    }

    &.button-toxic {
        background-color: $colorSuccess;
        border-color: $colorSuccess;
        color: #000;
        font-weight: 600;

        &:hover {
            background-color: lighten($color: $colorSuccess, $amount: 10%);
            border-color: lighten($color: $colorSuccess, $amount: 10%);
            box-shadow: $boxShadow;
        }

        &.button-primary-outline {
            background-color: transparent;
            border-color: $colorSuccess;
            color: $colorSuccess;

            &:hover {
                background-color: $colorSuccess;
                border-color: $colorSuccess;
                color: #fff;
            }
        }
    }

    &.button-default {
        background-color: #fff;
        border-color: $borderColor;
        color: #2f2e43;

        sup {
            color: $colorDanger;
        }

        &:hover {
            color: #9fa4b3;
            box-shadow: $boxShadow;
        }


        &.button-default-outline {
            background-color: rgba($color: #fff, $alpha: .2);
            border-color: #fff;
            color: #fff;
            font-weight: 500;

            sup {
                color: #fff;
            }

            &:hover {
                color: #fff;
                background-color: rgba($color: #fff, $alpha: .4);
                box-shadow: $boxShadow;
            }
        }
    }

    &.button-dark {
        background-color: #fff;
        border-color: #0C161D;
        color: #0C161D;

       

        &:hover {
            color: #9fa4b3;
            border-color: $borderColor;
            box-shadow: $boxShadow;
        }
    }

    &.button-icon {
        padding-left: 9px;
        padding-right: 9px;

        .mdi {
            &.start, &.end {
                margin: 0;
            }
        }

        &.button-icon-padless {
            padding: 0;
        }
    }

    &.button-text {
        padding-left: 0;
        padding-right: 0;

        &.button-text-light {
            color: #fff;
        }

        &.button-text-primary {
            color: $colorPrimary;
        }

        &.button-text-primary-light {
            color: $colorPrimaryLight;
        }

        &.button-text-danger {
            color: $colorDanger;
        }

        &.button-text-success {
            color: $colorSuccess;
        }


        
        &.button-disabled, &:disabled, &:disabled, &[disabled] {
            color: $tc;
            background: none;
            border: none;
        }

        &.button-disabled-text {
            color: #000000;
            font-size: 16px;
        }
    }

    &.button-menu {
        position: relative;
        outline: none;

        .menu-dropdown {
            position: absolute;
            display: flex;
            cursor: default;
            top: 100%;
            right: -10px;
            padding: 0;
            margin: 10px 0 0;
            z-index: 10;

            &>* {
                z-index: 2;
            }

            .menu-dropdown-arrow {
                position: absolute;
                bottom: 100%;
                right: 5px;
                z-index: 10;
                pointer-events: none;

                &:before, &:after {
                    display: block;
                    position: absolute;
                    content: '';
                    width: 0;
                    height: 0;
                    top: -18px;
                    right: 7px;
                    border: 10px solid transparent;
                    border-bottom: 10px solid #ffffff;
                    cursor: pointer;
                    z-index: 1;
                }
    
                &:before {
                    border-bottom-color: #ededed;
                    top: -20px;
                }
    
                &:after {
                    z-index: 2;
                }

                &.menu-dropdown-arrow-primary {
                    &:after {
                        border-bottom-color: #f7fafc;
                    }
                }
            }
            
            &.menu-dropdown-right {
                right: auto;
                left: -10px;
                
                .menu-dropdown-arrow {
                    right: auto;
                    left: 35px;
                }
            }

            &.menu-dropdown-stick {
                margin-top: 0;
            }
        }

        @include visible_on_toggle('.menu-dropdown', '.active');

        &.button-menu-hover {
            @include visible_on_hover('.menu-dropdown');
        }

        &.button-text {
            .menu-dropdown {
                .menu-dropdown-arrow {
                    right: 0;
                }
            }
        }
    }

    &.button-block {
        display: block;;
    }

    &.button-rounded {
        border-radius: 100px;
    }
    
    &.button-lg {
        padding: 13px 20px;
        font: 500 14px/20px $bf;

        &.button-text {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &.button-sm {
        padding: 6px 10px;
        font: 500 13px/18px $bf;

        &.button-text {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &.button-xs {
        padding: 5px 15px;
        font: 700 12px/18px $bf;
        
        &.button-text {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &.button-esm {
        padding: 4px 8px;
        font: 500 11px/15px $bf;

        &.button-text {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &.button-inline {
        display: inline-flex;
    }

    &.button-fill {
        width: 100%;
        justify-content: center;
        justify-items: center;
    }

    &.button-external {
        width: 100%;
        justify-content: center;
        justify-items: center;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px; 
        &:hover {
            color: #9fa4b3;
            box-shadow: $boxShadow;
        }
        .mdi {
            display: flex;
            font-size: 20px;
            line-height: inherit;
            color: $colorPrimary;
            &.gray-color {
               color: #9B9B9B;            
            }
            &.white-color {
                color: #FFFFFF;    
            }
        }
        &:hover .mdi {
            color: #9fa4b3;
        }
    }

    &.button-disabled, &:disabled, &:disabled, &[disabled] {
        pointer-events: none;
        opacity: .75;
        background-color: #f7fafc;
        border-color: #f7fafc;
        color: #9fa4b3;
    }
}

.button-group {
    display: flex;

   .button  {
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }
}